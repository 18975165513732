export const options = {
  0: {
    home: {
      students: "Students",
      attendance: "Attendance",
      timetable: "Timetable",
      subjects: "Subjects",
      classesAndSubjects: "Classes and subjects",
      faculties: "Faculties",
      facultiesAndSubjects: "Faculties and subjects",
      od: "OD",
      departments: "Departments",
      semesters: "Semesters",
      createSemester: "Create academic semester",
      workingDays: "Working days",
      studentsAndSubjects: "Students and subjects",
      changePassword: "Change password",
    },
    students: { view: "View", add: "Add", addMore: "Add more" },
    attendance: {
      view: "View",
      viewDetails: "View - detailed",
      viewOverall: "View overall",
      viewOverallDetails: "View overall - detailed",
      mark: "Mark",
    },
    timetable: {
      view: "View all",
      viewMyTimetable: "View my timetable",
      add: "Add",
    },
    subjects: { view: "View", add: "Add" },
    classesAndSubjects: { view: "View", add: "Add" },
    faculties: { view: "View", add: "add" },
    facultiesAndSubjects: { view: "View", add: "Add" },
    od: { view: "View", apply: "Apply" },
    departments: { view: "View", add: "Add" },
    semesters: { view: "View", add: "Add" },
    workingdays: { view: "View", add: "Add", addMore: "Add more" },
    studentsAndSubjects: { view: "View", add: "Add" },
  },
  1: {
    home: {
      students: "Students",
      attendance: "Attendance",
      timetable: "Timetable",
      subjects: "Subjects",
      classesAndSubjects: "Classes and subjects",
      faculties: "Faculties",
      facultiesAndSubjects: "Faculties and subjects",
      workingDays: "Working days",
      studentsAndSubjects: "Students and subjects",
      changePassword: "Change password",
    },
    students: { view: "View" },
    attendance: {
      view: "View",
      viewDetails: "View - detailed",
      mark: "Mark",
    },
    timetable: {
      view: "View all",
      viewMyTimetable: "View my timetable",
    },
    subjects: { view: "View" },
    classesAndSubjects: { view: "View" },
    faculties: { view: "View" },
    facultiesAndSubjects: { view: "View" },
    workingdays: { view: "View" },
    studentsAndSubjects: { view: "View" },
  },
  2: {
    home: {
      students: "Students",
      attendance: "Attendance",
      timetable: "Timetable",
      subjects: "Subjects",
      classesAndSubjects: "Classes and subjects",
      faculties: "Faculties",
      facultiesAndSubjects: "Faculties and subjects",
      od: "OD",
      workingDays: "Working days",
      studentsAndSubjects: "Students and subjects",
      changePassword: "Change password",
    },
    students: { view: "View", add: "Add", addMore: "Add more" },
    attendance: {
      view: "View",
      viewDetails: "View - detailed",
      viewOverall: "View overall",
      viewOverallDetails: "View overall - detailed",
      mark: "Mark",
    },
    timetable: {
      view: "View all",
      viewMyTimetable: "View my timetable",
      add: "Add",
    },
    subjects: { view: "View" },
    classesAndSubjects: { view: "View", add: "Add" },
    faculties: { view: "View" },
    facultiesAndSubjects: { view: "View", add: "Add" },
    od: { view: "View", apply: "Apply" },
    workingdays: { view: "View", add: "Add", addMore: "Add more" },
    studentsAndSubjects: { view: "View", add: "Add" },
  },
  3: {
    home: {
      students: "Students",
      attendance: "Attendance",
      timetable: "Timetable",
      subjects: "Subjects",
      classesAndSubjects: "Classes and subjects",
      faculties: "Faculties",
      facultiesAndSubjects: "Faculties and subjects",
      od: "OD",
      workingDays: "Working days",
      studentsAndSubjects: "Students and subjects",
      changePassword: "Change password",
    },
    students: { view: "View" },
    attendance: {
      view: "View",
      viewDetails: "View - detailed",
      viewOverall: "View overall",
      viewOverallDetails: "View overall - detailed",
      mark: "Mark",
    },
    timetable: {
      view: "View all",
      viewMyTimetable: "View my timetable",
    },
    subjects: { view: "View" },
    classesAndSubjects: { view: "View" },
    faculties: { view: "View" },
    facultiesAndSubjects: { view: "View" },
    od: { view: "View" },
    workingdays: { view: "View" },
    studentsAndSubjects: { view: "View" },
  },
  4: {
    home: {
      students: "Students",
      attendance: "Attendance",
      timetable: "Timetable",
      subjects: "Subjects",
      classesAndSubjects: "Classes and subjects",
      faculties: "Faculties",
      facultiesAndSubjects: "Faculties and subjects",
      od: "OD",
      workingDays: "Working days",
      studentsAndSubjects: "Students and subjects",
      changePassword: "Change password",
    },
    students: { view: "View" },
    attendance: {
      view: "View",
      viewDetails: "View - detailed",
      viewOverall: "View overall",
      viewOverallDetails: "View overall - detailed",
      mark: "Mark",
    },
    timetable: {
      view: "View all",
      viewMyTimetable: "View my timetable",
    },
    subjects: { view: "View" },
    classesAndSubjects: { view: "View" },
    faculties: { view: "View" },
    facultiesAndSubjects: { view: "View" },
    od: { view: "View" },
    workingdays: { view: "View" },
    studentsAndSubjects: { view: "View" },
  },
  5: {
    home: {
      subjects: "Subjects",
      faculties: "Faculties",
      departments: "Departments",
      changePassword: "Change password",
    },
    subjects: { view: "View", add: "Add", addMore: "Add more" },
    faculties: { view: "View", add: "Add" },
    departments: { view: "View", add: "Add" },
  },
};
